import styled from 'styled-components';

export const FormpageWrapper = styled.div`
	.animation-block {
	    position: absolute;
	    background-color: #ff900f;
	    opacity: 0.7;
	    width: 100%;
	    height: 100%;
	    z-index: 9999;
		.spinner-border {
		   margin: 25% auto;
		   display: block;
		}
	}
	.hiddenfiled {
		display:none;
	}
	.right-block {
		height: 100%;
	    background-color: #1d232e;
	    text-align: center;
	    padding: 0px 30px;
		.blackbox{
			padding-top: 30vh;
			.sidebartitle{
				color: #b5b9c1;
				font-size: 18px;
				li {
				    width: 50%;
				    display: inline-grid;
				    height: auto;
				}
			}
			.sidebarimages li {
				.gatsby-image-wrapper {
					img{
						object-fit: none !important;
					}
				}
			    width: 50%;
			    display: inline-table;
			    vertical-align: middle;
			}
		    
		}
	}
	.left-block {
		min-height: 100vh;
    	padding: 20px 0px 50px;
		.form-block{
			@media (max-width: 600px) {
				
			}
			.logo{
				text-align: center;
				padding: 25px 0px;
				width: 170px;
				margin: auto;
				img {
				    width: 170px;
				}
			}
			.btn-custom	{
				background-color: #fb785d;
				border: 1px solid #fb785d;
				padding: 10px 35px;
				color: #fff;
				&:hover{
					background-color: #fb785c;
				}
				&:focus{
			    	box-shadow: none !important;
			    }
			    @media (max-width: 600px) {
			    	margin: 0px 15px 20px 0px;
			    }
			}
			.float-none {
			    display: block;
			    margin: auto;
			}
			.custom-link {
				color: #212529;
				padding-left: 0px;
				&:focus{
			    	box-shadow: none !important;
			    }
			}
			.form-group {
				@media (max-width: 600px) {
					padding: 50px 20px 20px 20px;
				    border-bottom: 0px solid #ddd;
				    margin-bottom: 0px;
				}
			    padding: 50px 0px 20px 0px;
			    border-bottom: 1px solid #ddd;
			    margin-bottom: 30px;
			    	.form-check-label {
				    font-weight: 800;
				    font-size: 15px;
				    padding-left: 15px;
				    cursor: pointer;
					    p {
						    font-weight: normal;
						    font-family: Avenir Next LT Pro Regular !important;
						}
					}
					label {
					    font-weight: 800;
					    font-size: 15px;
					    padding-left: 15px;
					    cursor: pointer;
					}
				    input[type='text'] {
					    border-bottom: 1px solid #ddd;
					    border-top: 1px solid #fff !important;
					    border-left: 1px solid #fff !important;
					    border-radius: 0px !important;
					    border-right: 1px solid #fff !important;
					    padding-left: 0;
					    &:focus{
					    	box-shadow: none !important;
					    }
					    ::placeholder {
						  color: #cecaca;
						  opacity: 1; 
						}
					}
					input[type='email'] {
					    border-bottom: 1px solid #ddd;
					    border-top: 1px solid #fff !important;
					    border-left: 1px solid #fff !important;
					    border-radius: 0px !important;
					    border-right: 1px solid #fff !important;
					    padding-left: 0;
					    &:focus{
					    	box-shadow: none !important;
					    }
					    ::placeholder {
						  color: #cecaca;
						  opacity: 1; 
						}
					}
					input[type='radio'] {
				        -webkit-appearance: none;
				        width: 20px;
				        height: 20px;
				        border-radius: 50%;
				        outline: none;
				        border: 1.5px solid #00a3ff;
				        top: 10px;
				    }
				    input[type='radio']:before {
				        content: '';
				        display: block;
				        width: 60%;
				        height: 60%;
				        margin: 20% auto;
				        border-radius: 50%;
				    }
				    input[type="radio"]:checked:before {
				        background: #00a3ff; 
				    }
				    
				    input[type="radio"]:checked {
				      border-color: #00a3ff;
				    }
			}
			.stepthree {
				.form-group {
					border-bottom: 0px;
				}
			}
			.stepfive {
				.form-group {
					border-bottom: 0px;
				}
			}
			.stepthead {
				text-align: center;
				h2 {
				    font-size: 20px;
				    padding: 10px 0px;
				    line-height: 33px;
				}
				h3{
					font-size: 16px;
					font-family: Avenir Next LT Pro Regular !important;
				}
			}
			.submit-text {
			    text-align: center;
			    p {
				        color: #cecaca;
    					font-size: 15px;
				    a {
					    text-decoration: underline !important;
					}
				}
			}
			.form-check {
			    padding: 12px 20px;
			}
			.stepone{
				label {
				    p {
					    font-weight: normal;
					}
				}
				.form-check {
				    padding: 8px 20px;
				}
			} 
		}
	}

`;

export default FormpageWrapper;